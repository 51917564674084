.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f4f4f4;
}

.App-header {
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.App-header h1 {
  font-size: 2.5em;
  color: #333;
}

.App-header p {
  color: #666;
}

.App-main {
  padding: 20px;
}

section {
  margin-bottom: 40px;
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #666;
}
@media (max-width: 600px) {
  .App-main .address-container {
    font-size: 14px;
  }
}


.center-pre {
  font-size: 1.5em;
  text-align: center;
  background-color: #eee;
  padding: 20px;
  overflow-x: auto;
}

.verify-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.form-input {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.verify-button {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.App-footer {
  background-color: #fff;
  padding: 20px;
  border-top: 1px solid #ddd;
}

.small-text {
  font-size: 0.8em;
  color: #999;
}

.App-logo {
  width: 100%;
  height: auto;
  max-width: 150px;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
}

.logo {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .App-header {
    flex-direction: column;
  }

  .logo {
    margin-bottom: 10px;
  }
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-lock {
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin: 0;
}

p {
  margin: 0;
}

button {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.wallet-connect-container {
  text-align: center;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.wallet-connect-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.address-container {
  margin-bottom: 20px;
}

.address-text {
  font-size: 18px;
  margin-bottom: 10px;
  word-break: break-word;
}

.connect-button-container {
  margin-bottom: 20px;
}

.connect-button {
  font-size: 16px;
}

.sign-message-container {
  margin-bottom: 20px;
}

.message-input {
  width: 100%;
  height: 100px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.sign-message-button {
  font-size: 16px;
}

.ordv-json-container {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
}

.ordv-json-text {
  font-size: 16px;
  white-space: pre-wrap;
  word-break: break-word;
}

.copy-button {
  margin-top: 10px;
  font-size: 16px;
}

